import { createStore, combineReducers } from 'redux';

// Action Types
const SET_TAB = 'SET_TAB';
const SET_SELECTED_ASSIGNMENT = 'SET_SELECTED_ASSIGNMENT';
const SET_SELECTED_COMPONENT = 'SET_SELECTED_COMPONENT'; // Новый тип действия для выбора компонента
const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE';
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_SELECTED_STEP = 'SET_SELECTED_STEP';
const ADD_COMPONENT = 'ADD_COMPONENT';
const DELETE_COMPONENT = 'DELETE_COMPONENT';
const UPDATE_COMPONENT = 'UPDATE_COMPONENT';
const SET_COMPONENTS = 'SET_COMPONENTS';
const ADD_COMPONENTS = 'ADD_COMPONENTS';
const CHANGE_COMPONENT = 'CHANGE_COMPONENT';
const RESORT_COMPONENTS = 'RESORT_COMPONENTS';
const ADD_PLUGIN = 'ADD_PLUGIN';
const DELETE_PLUGIN = 'DELETE_PLUGIN';
const UPDATE_PLUGIN = 'UPDATE_PLUGIN';
const SET_PLUGINS = 'SET_PLUGINS';
const SET_EDITABLE_PLUGIN = 'SET_EDITABLE_PLUGIN';
const SET_ADDED_DIRECTION = 'SET_ADDED_DIRECTION';
const SHOW_NAVIGATION = 'SHOW_NAVIGATION';
const TOGGLE_SUBSCRIPTION_DIALOG = 'TOGGLE_SUBSCRIPTION_DIALOG'; // Новый тип действия

// Action Creators
export const setTab = (tab) => ({
    type: SET_TAB,
    payload: tab,
});

export const setAddedDirection = (directionData) => ({
    type: SET_ADDED_DIRECTION,
    payload: directionData,
});

export const setShowNavigation = (isVisible) => ({
    type: SHOW_NAVIGATION,
    payload: isVisible,
});

export const setSelectedAssignment = (assignment) => ({
    type: SET_SELECTED_ASSIGNMENT,
    payload: assignment,
});

export const setEditablePlugin = (plugin) => ({
    type: SET_EDITABLE_PLUGIN,
    payload: plugin,
});

export const setSelectedCourse = (course) => ({
    type: SET_SELECTED_COURSE,
    payload: course,
});

export const setAccessToken = (token) => ({
    type: SET_ACCESS_TOKEN,
    payload: token,
});

export const setSelectedStep = (step) => ({
    type: SET_SELECTED_STEP,
    payload: step,
});

export const addComponent = (component) => ({
    type: ADD_COMPONENT,
    payload: component,
});

export const deleteComponent = (componentId) => ({
    type: DELETE_COMPONENT,
    payload: componentId,
});

export const updateComponent = (component) => ({
    type: UPDATE_COMPONENT,
    payload: component,
});

export const setComponents = (components) => ({
    type: SET_COMPONENTS,
    payload: components,
});

export const addComponents = (components) => ({
    type: ADD_COMPONENTS,
    payload: components,
});

export const changeComponent = (component) => ({
    type: CHANGE_COMPONENT,
    payload: component,
});

export const resortComponents = (components) => ({
    type: RESORT_COMPONENTS,
    payload: components,
});

export const setSelectedComponent = (component) => ({
    type: SET_SELECTED_COMPONENT,
    payload: component, // Новый экшен для выбора компонента
});

export const addPlugin = (plugin) => ({
    type: ADD_PLUGIN,
    payload: plugin,
});

export const deletePlugin = (pluginId) => ({
    type: DELETE_PLUGIN,
    payload: pluginId,
});

export const updatePlugin = (plugin) => ({
    type: UPDATE_PLUGIN,
    payload: plugin,
});

export const setPlugins = (plugins) => ({
    type: SET_PLUGINS,
    payload: plugins,
});

export const toggleSubscriptionDialog = (isOpen) => ({
    type: TOGGLE_SUBSCRIPTION_DIALOG,
    payload: isOpen,
});

// Initial State
const initialState = {
    tab: 0,
    selectedAssignment: null,
    selectedCourse: null,
    accessToken: null,
    selectedStep: null,
    selectedComponent: null, // Добавляем состояние для выбранного компонента
    components: [],
    plugins: [],
    editablePlugin: null,
    addedDirection: null,
    showNavigation: true,
    isSubscriptionDialogOpen: false, // Новое состояние для диалога подписки
};

// Reducers
const tabReducer = (state = initialState.tab, action) => {
    switch (action.type) {
        case SET_TAB:
            return action.payload;
        default:
            return state;
    }
};

const showNavigationReducer = (state = initialState.showNavigation, action) => {
    switch (action.type) {
        case SHOW_NAVIGATION:
            return action.payload;
        default:
            return state;
    }
};

const assignmentReducer = (state = initialState.selectedAssignment, action) => {
    switch (action.type) {
        case SET_SELECTED_ASSIGNMENT:
            return action.payload;
        default:
            return state;
    }
};

const courseReducer = (state = initialState.selectedCourse, action) => {
    switch (action.type) {
        case SET_SELECTED_COURSE:
            return action.payload;
        default:
            return state;
    }
};

const editablePluginReducer = (state = initialState.editablePlugin, action) => {
    switch (action.type) {
        case SET_EDITABLE_PLUGIN:
            return action.payload;
        default:
            return state;
    }
};

const accessTokenReducer = (state = initialState.accessToken, action) => {
    switch (action.type) {
        case SET_ACCESS_TOKEN:
            return action.payload;
        default:
            return state;
    }
};

const addedDirectionReducer = (state = initialState.addedDirection, action) => {
    switch (action.type) {
        case SET_ADDED_DIRECTION:
            return action.payload;
        default:
            return state;
    }
};

const selectedStepReducer = (state = initialState.selectedStep, action) => {
    switch (action.type) {
        case SET_SELECTED_STEP:
            return action.payload;
        default:
            return state;
    }
};

const selectedComponentReducer = (state = initialState.selectedComponent, action) => {
    switch (action.type) {
        case SET_SELECTED_COMPONENT:
            return action.payload;
        default:
            return state;
    }
};

const componentsReducer = (state = initialState.components, action) => {
    switch (action.type) {
        case ADD_COMPONENT:
            state = [...state, action.payload];
            state.sort((a, b) => a.sortIndex - b.sortIndex);
            return state;

        case DELETE_COMPONENT:
            return state.filter(component => component.id !== action.payload);
        case UPDATE_COMPONENT:
            return state.map(component =>
                component.id === action.payload.id ? action.payload : component
            );
        case SET_COMPONENTS:
            action.payload.sort((a, b) => a.sortIndex - b.sortIndex);
            return action.payload;
        case ADD_COMPONENTS:
            return [...state, ...action.payload];
        case CHANGE_COMPONENT: {
            const index = state.findIndex(component => component.id === action.payload.id);
            if (index !== -1) {
                const updatedComponents = [...state];
                updatedComponents[index] = action.payload;
                updatedComponents.sort((a, b) => a.sortIndex - b.sortIndex);
                return updatedComponents;
            }
            return state;
        }
        case RESORT_COMPONENTS: {
            if (action.payload && action.payload.length > 0) {
                const updatedComponents = [...state];
                action.payload.forEach(resortItem => {
                    const component = updatedComponents.find(c => c.id === resortItem.id);
                    if (component) {
                        component.sortIndex = resortItem.index;
                    }
                });
                updatedComponents.sort((a, b) => a.sortIndex - b.sortIndex);
                return updatedComponents;
            }
            return state;
        }
        default:
            return state;
    }
};

const subscriptionDialogReducer = (state = initialState.isSubscriptionDialogOpen, action) => {
    switch (action.type) {
        case TOGGLE_SUBSCRIPTION_DIALOG:
            return action.payload;
        default:
            return state;
    }
};

const pluginsReducer = (state = initialState.plugins, action) => {
    switch (action.type) {
        case ADD_PLUGIN:
            return [...state, action.payload];
        case DELETE_PLUGIN:
            return state.filter(plugin => plugin.id !== action.payload);
        case UPDATE_PLUGIN:
            return state.map(plugin =>
                plugin.id === action.payload.id ? action.payload : plugin
            );
        case SET_PLUGINS:
            return action.payload;
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    tab: tabReducer,
    selectedAssignment: assignmentReducer,
    selectedCourse: courseReducer,
    accessToken: accessTokenReducer,
    selectedStep: selectedStepReducer,
    selectedComponent: selectedComponentReducer,
    components: componentsReducer,
    plugins: pluginsReducer,
    editablePlugin: editablePluginReducer,
    addedDirection: addedDirectionReducer,
    showNavigation: showNavigationReducer,
    isSubscriptionDialogOpen: subscriptionDialogReducer,
});

const store = createStore(rootReducer);

export default store;